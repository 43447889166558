<template>
  <f7-page class="weeklyrecommend">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_RECOMMENDATION')" searchname="shop" @searched="onSearch" />
    </template>
    <section class="">
      <div class="">
        <div v-if="isGettingList" class="preloader-cont">
          <f7-preloader></f7-preloader>
        </div>

        <div v-if="productList.length" class="">
          <div class="product-container">
            <template v-for="(product, i) in productList" :key="'rc_' + i">
              <ProductListCardComponent :data="product" :f7router="f7router" :category="true" :isShop="true" />
            </template>
          </div>
        </div>

        <NoDataFoundComponent v-if="!isGettingList && productList.length == 0" :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_PRODUCT_FOUND')" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed, inject, defineAsyncComponent } from "vue";

import { get, post } from "@/utils/axios";
import { useStore } from "@/store";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));

export default defineComponent({
  name: "WeeklyRecommendPage",
  components: {
    NoDataFoundComponent,
    ProductListCardComponent,
    ProductNavigationComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup() {
    const store = useStore();

    let isGettingList = ref(true);
    const productList = ref([]);
    const showPreloader = ref(false);
    const $t = inject("$translation");
    const user = computed(() => store.getters["user/getData"]);
    const IsViewWeeklyProduct = computed(() => store.getters["user/getData"]?.IsViewWeeklyProduct);

    store.dispatch("config/fetchData", { params: { weeklyProductLogList: 1 } });
    const weeklyProductLog = computed(() => store.getters["config/getData"]?.weeklyProductLogList);

    const userToken = computed(() => store.getters["user/getToken"]);
    let page = 1;
    let size = 30;
    let lastPage = 1;

    const getProductList = async (isDefault) => {
      let obj = {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
        mode: "WEEKLYRECOMMEND",
      };

      let ret = await get("/mobile/product/public/list", obj);
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          productList.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }

      isGettingList.value = false;
    };

    const loadMore = () => {
      return lastPage >= page ? getProductList() : false;
    };

    const checkWeekyProductLog = async () => {
      let ret = await get(`/user/weekly/product/notification?WeeklyProductLogId=${weeklyProductLog.value.WeeklyProductLogId}`);
      store.dispatch("user/setData", { IsViewWeeklyProduct: ret.IsView });
      if (IsViewWeeklyProduct.value) {
        store.dispatch("user/setData", { IsViewWeeklyProduct: false });
        await post("/user/weekly/product/notification", { WeeklyProductLogId: weeklyProductLog.value.WeeklyProductLogId });
      }
    };

    onMounted(async () => {
      store.dispatch("config/fetchData", { force: true });
      if (userToken.value && weeklyProductLog.value) {
        await checkWeekyProductLog();
      }
      await getProductList();
    });

    return { productList, isGettingList, loadMore, showPreloader };
  },
});
</script>

<style>
.bg-gray {
  width: 100%;
  min-height: 735px;
}
.weeklyrecommend-container > .container {
  margin: 10px 10px;
}

.product-container {
  /* padding-right: 30px; */
  padding-left: 10px;
  padding-top: 10px;
}
</style>
